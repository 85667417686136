import React from 'react';
import FontAwesome from 'react-fontawesome';
import I18n from '../../resources/i18n/I18n';
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";
import eventApi from 'services/eventApi';
import { useHistory } from "react-router-dom";
import CustomButton from 'components/buttons/CustomButton';
import { downloadExport, getExportStatus } from 'services/exportService';
import Progress from 'components/progress/Progress';
import CheckboxField from 'components/checkbox/CheckboxField';
import toast from 'utils/toast';

const ActionBar = ({
    actions = {},
    addEvent,
    markAsRead,
    eventsToExport = [],
    toggleFilters,
    filters = {},
    tooMuchEvents,
    resultsCount = 0,
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const [jobs, setJobs] = React.useState([]);
    const [jobNumber, setJobNumber] = React.useState(0);
    const [exportAll, setExportAll] = React.useState(false);
    const [exportType, setExportType] = React.useState(eventsToExport.length ? 'pdf' : 'csv');
    const [downloading, setDownloading] = React.useState(false);

    const toggleExportModal = () => {
        if (jobs.length) {
            return;
        }
        setShowModal(!showModal);
        setDownloading(false);
    };

    const getExportButtonTitle = () => {
        if (eventsToExport.length && !exportAll) {
            return 'ExportConfirmSelected';
        }


        return 'ExportConfirmAll';
    }

    const changeExportType = (type) => {

        if (jobNumber > 0) {
            return;
        }

        setExportType(type);
        if (type !== 'csv' && exportAll) {
            setExportAll(false);
        }
    }

    /**
     * Permet de générer un export PDF ou CSV
     */
    const generateExport = async () => {
        eventApi.exportEvents(exportType, {
            eventIdList: exportType === 'csv' && exportAll ? undefined : eventsToExport.map(event => event.id),
            ...filters
        })
            .then(({ jobList, exportId }) => {
                setJobs(jobList);
                setJobNumber(jobList.length);
                pollProgress(jobList, exportId)
            });
    };

    const renderModalBody = () => loading ? (
        <Spinner animation="border" variant="primary" />
    ) : (
        <div className='d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-center align-items-center'>
                {eventsToExport.length > 0 && (
                    <span onClick={() => changeExportType('pdf')} className={`icon-pdf p-1 ${exportType === 'pdf' ? 'border' : ''}`} />
                )}

                {
                    <span className={`icon-csv p-1 ${exportType === 'csv' ? 'border' : ''}`} onClick={() => changeExportType('csv')}></span>
                }
            </div>
            <CheckboxField
                name="selectAll"
                className={`${exportType === 'csv' && eventsToExport.length ? 'mt-4 mb-3' : 'invisible'}`}
                value={exportAll}
                onChange={() => setExportAll(!exportAll)}
                label={I18n.t('ExportConfirmAll')}
            />
            <CustomButton onClick={generateExport} small primary title={I18n.t(getExportButtonTitle())} disabled={jobs.length} />
            {jobNumber > 0 && <span className='mt-2 mb-2'>{I18n.t('ExportProgress')}</span>}
            {jobNumber > 0 && (
                <Progress value={100 - (jobs.length / jobNumber) * 100} />
            )}
        </div>
    );

    const downloadExportAfter = async (exportId) => {
        if (downloading) {
            return;
        }

        await downloadExport(exportId);
        setLoading(false);
        setJobNumber(0);
        setExportAll(false);
        toggleExportModal();
    };

    const pollProgress = async (jobsOverride, exportId) => {
        try {
            if (jobsOverride.length === 0 || (!jobsOverride && jobs.length === 0)) {
                setLoading(false);
                setJobNumber(0);
                setExportAll(false);
                toggleExportModal();
                return;
            }

            const newJobs = await getExportStatus(jobsOverride || jobs);
            setJobs(newJobs);

            if (newJobs.length === 0) {
                setDownloading(true);
                await downloadExportAfter(exportId);
            } else {
                setTimeout(() => pollProgress(newJobs, exportId), 1000);
            }
        } catch (error) {
            setLoading(false);
            setJobNumber(0);
            setExportAll(false);
            toggleExportModal();
            toast.error(I18n.t('ExportError'));
        }
    }

    return (
        <div>
            <div className="d-flex gap-x-2">
                {markAsRead ?
                    <div className="d-flex flex-column justify-content-end">
                        <a href="#0" className={`link ${eventsToExport.length === 0 && 'disabled'}`} onClick={markAsRead}>{I18n.t('MarkAsRead')}</a>
                    </div>
                    : null
                }
                <div className="action-button small d-md-none d-block" onClick={() => toggleFilters(true)}>
                    <FontAwesome name="sliders" />
                    <span className="icon-excel" />
                </div>
                {actions.export &&
                    <div className={`action-button small ${resultsCount ? '' : 'disabled'}`} onClick={!resultsCount ? null : toggleExportModal}>
                        <FontAwesome name="upload black" />
                    </div>
                }

                {addEvent ?
                    <div className="action-button small" onClick={() => history.push('/signalement')}>
                        <FontAwesome name="plus" />
                    </div>
                    : null
                }
            </div>
            <Modal className="modal-export" show={showModal && !tooMuchEvents} onHide={toggleExportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{I18n.t('ExportEvent')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex justify-content-center align-items-center">
                    {renderModalBody()}
                </Modal.Body>
            </Modal>
            <Modal className="modal-export" show={showModal && tooMuchEvents} onHide={toggleExportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{I18n.t('ExportEvent')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <span>{I18n.t('ExportTooMuchEvents')}</span>
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default ActionBar;
